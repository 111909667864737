import { template as template_cc296be502f74e98b767a9db3a4aa632 } from "@ember/template-compiler";
const SidebarSectionMessage = template_cc296be502f74e98b767a9db3a4aa632(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
