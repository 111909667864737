import { template as template_468b0ba8126846ce9494650976565767 } from "@ember/template-compiler";
const FKLabel = template_468b0ba8126846ce9494650976565767(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
