import { template as template_6e652fab105b4dc8b4efd2aa4f9e7394 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_6e652fab105b4dc8b4efd2aa4f9e7394(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
